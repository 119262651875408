const TEXT_COLUMN_HAS_OVERFLOW = 'text-column--has-overflow'
const TEXT_COLUMN_IS_EXPANDED = 'text-column--is-expanded'

export default class HomeTextColumn {
  constructor() {
    this.textColumn = document.querySelector('.text-column')
    this.textColumnContent = document.querySelector('.text-column__content')
    this.textColumnOverflow = document.querySelector('.text-column__overflow')
    this.textColumnResizeButtons = document.querySelectorAll('.text-column__resize')
    this.onWindowResize = this.onWindowResize.bind(this)
    this.setTextOverflowStatus = this.setTextOverflowStatus.bind(this)
    this.toggleTextOverflow = this.toggleTextOverflow.bind(this)

    if (this.textColumn) {
      this.init()
    }
  }

  init() {
    this.setTextOverflowStatus()
    window.addEventListener('resize', this.onWindowResize)

    this.textColumnResizeButtons.forEach((button) => {
      button.addEventListener('click', this.toggleTextOverflow)
    })
  }

  onWindowResize() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setTextOverflowStatus()
    }, 300)
  }

  setTextOverflowStatus() {
    const textColumnContentHeight = this.textColumnContent?.clientHeight
    const textColumnOverflowHeight = this.textColumnOverflow?.clientHeight

    if (!this.textColumn || !textColumnContentHeight || !textColumnOverflowHeight) {
      return
    }

    this.textColumn.classList.remove(TEXT_COLUMN_HAS_OVERFLOW)

    if (textColumnOverflowHeight >= textColumnContentHeight) {
      this.textColumn.classList.add(TEXT_COLUMN_HAS_OVERFLOW)
    }
  }

  toggleTextOverflow() {
    this.textColumn.classList.toggle(TEXT_COLUMN_IS_EXPANDED)
  }
}
