import '../scss/home22.scss'
import '../scss/reviews_recent22.scss'
import HomeTextColumn from './components/HomeTextColumn'

new HomeTextColumn()

const redirectResponse = document.getElementById('redirect-response')
if (redirectResponse !== null) {
    swal(redirectResponse.dataset.title, redirectResponse.dataset.description)
}

const prevBtnSvgPath = '<path d="M18.878 0.00567265C19.6062 0.00424113 20.3119 0.259425 20.8726 0.726929C21.1882 0.990185 21.449 1.3135 21.6402 1.67836C21.8314 2.04321 21.9492 2.44243 21.9868 2.85316C22.0245 3.26389 21.9812 3.67806 21.8595 4.07194C21.7378 4.46582 21.5401 4.83167 21.2777 5.14855L7.3159 21.957L20.7791 38.7968C21.038 39.1176 21.2313 39.4866 21.3479 39.8828C21.4646 40.279 21.5023 40.6945 21.4588 41.1054C21.4154 41.5163 21.2917 41.9145 21.0948 42.2771C20.8979 42.6397 20.6318 42.9596 20.3116 43.2184C19.9892 43.5039 19.6116 43.7192 19.2025 43.8509C18.7934 43.9826 18.3617 44.0277 17.9344 43.9836C17.5072 43.9394 17.0936 43.8069 16.7197 43.5942C16.3458 43.3816 16.0196 43.0935 15.7616 42.748L0.708963 23.9326C0.250585 23.3715 2.49363e-06 22.6677 2.55713e-06 21.9413C2.62062e-06 21.215 0.250586 20.5111 0.708963 19.95L16.2914 1.1346C16.604 0.755101 17.0011 0.455108 17.4506 0.258961C17.9 0.0628141 18.389 -0.0239635 18.878 0.00567265Z" style="fill: var(--light-grey-22);"/>';
const nextBtnSvgPath = '<path d="M3.12196 43.9943C2.39379 43.9958 1.68811 43.7406 1.12742 43.2731C0.811847 43.0098 0.550992 42.6865 0.35979 42.3216C0.168588 41.9568 0.0507964 41.5576 0.0131622 41.1468C-0.024472 40.7361 0.0187911 40.3219 0.140472 39.9281C0.262154 39.5342 0.45986 39.1683 0.722274 38.8514L14.6841 22.043L1.22091 5.20321C0.96204 4.88244 0.768719 4.51336 0.652064 4.11717C0.535409 3.72098 0.497719 3.3055 0.54116 2.89461C0.584601 2.48372 0.708315 2.08552 0.905195 1.72289C1.10208 1.36027 1.36824 1.04037 1.68838 0.781584C2.01083 0.496103 2.38844 0.280768 2.79752 0.149094C3.2066 0.0174208 3.63831 -0.0277478 4.06556 0.016421C4.4928 0.0605898 4.90636 0.193144 5.28029 0.405764C5.65421 0.618384 5.98042 0.906479 6.23844 1.25197L21.291 20.0674C21.7494 20.6285 22 21.3323 22 22.0587C22 22.785 21.7494 23.4889 21.291 24.05L5.70864 42.8654C5.39601 43.2449 4.99886 43.5449 4.54943 43.741C4.10001 43.9372 3.61096 44.024 3.12196 43.9943Z" style="fill: var(--light-grey-22);"/>';
const btnHtml = '<button type="button" class="%s" aria-label="%s"><svg width="22" height="44" viewBox="0 0 22 44" fill="none" xmlns="http://www.w3.org/2000/svg">%s</svg></button>'

const productSliderOptions =  {
    infinite: true,
    dots: false,
    slidesToShow: 4,
    responsive: [
        {
            breakpoint: 1000,
            settings: { slidesToShow: 2 }
        },
        {
            breakpoint: 600,
            settings: { slidesToShow: 2 }
        },
        {
            breakpoint: 480,
            settings: { slidesToShow: 1 }
        }
    ],
    prevArrow: btnHtml
      .replace('%s', 'product-slider__prev slick-prev')
      .replace('%s', 'Previous')
      .replace('%s', prevBtnSvgPath),
    nextArrow: btnHtml
      .replace('%s', 'product-slider__next slick-next')
      .replace('%s', 'Next')
      .replace('%s', nextBtnSvgPath),
}

const reviewSliderOptions = {
  infinite: true,
  mobileFirst: true,
  swipeToSlide: true,
  dots: false,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 680,
      settings: { slidesToShow: 2 }
    },
    {
      breakpoint: 1024,
      settings: { slidesToShow: 3 }
    },
    {
      breakpoint: 1240,
      settings: { slidesToShow: 4 }
    }
  ],
  prevArrow: btnHtml
    .replace('%s', 'product-slider__prev slick-prev')
    .replace('%s', 'Previous')
    .replace('%s', prevBtnSvgPath),
  nextArrow: btnHtml
    .replace('%s', 'product-slider__next slick-next')
    .replace('%s', 'Next')
    .replace('%s', nextBtnSvgPath),
}

$('.product-slider-wrapper').slick(productSliderOptions)
$('.recent-reviews__slider').slick(reviewSliderOptions)

function handleRecentReviewLinks() {
  const recentReviews = document.querySelector('.recent-reviews');
  if (!recentReviews) {
    return;
  }
  const reviewsUrl = recentReviews.dataset.url
  if (!reviewsUrl) {
    return;
  }
  
  const reviewsStars = document.querySelector('.recent-reviews__stars');
  const reviewsDisplay = document.querySelector('.recent-reviews__display');
  
  [reviewsStars, reviewsDisplay].forEach((item) => {
    item.style.cursor = 'pointer';
    item.href = reviewsUrl;
    item.addEventListener('click', function() {
      document.location = reviewsUrl;
    })
  });
}

handleRecentReviewLinks();

function handleLazyVideo() {
  const videoImage = document.querySelector('.video-bar__image');
  const videoElement = document.querySelector('.video-bar__element');
  const videoUrl = videoImage.dataset.videoUrl;
  if (!videoImage || !videoElement || !videoUrl) {
    return;
  }

  const iframe = `
    <iframe
      src="${videoUrl}"
      class="video-bar__video-iframe"
      allow="autoplay"
      allowfullscreen
    ></iframe>
  `;

  function replaceImageWithVideo() {
    videoImage.remove();
    videoElement.classList.add('video-bar__element--active');
    videoElement.insertAdjacentHTML('afterbegin', iframe);
  }

  const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
  
  if (isIOS) {
    return replaceImageWithVideo();
  }

  videoImage.addEventListener('click', () => {
    replaceImageWithVideo();
  });
}

handleLazyVideo();
